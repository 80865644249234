import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { App } from 'src/components/App';
import { ThemeProvider, ThemeType } from 'src/contexts/theme/ThemeContext';
import { Login } from 'src/components/Login';

export const Root = () => {
	const [theme] = React.useState(undefined);
	React.useEffect(() => {
		// fetchTheme().then(theme => setTheme(theme))
	}, [])

	return (
		<>
			{
				theme ? <div>Loading...</div> : (
					<ThemeProvider theme={{
						light: { danger: 'rgb(23, 125, 220)', main: '#c07036', backgroundColor: 'black' },
						dark: { danger: 'rgb(23, 125, 220)', main: '#c07036', backgroundColor: 'black' }, // TODO: colors not properly mapped to MUI theme colors yet
						type: ThemeType.Light,
					}}>
						<Router>
							<Switch>
								<Route path='/login'>
									<Login />
								</Route>
								<Route path='/'>
									<App />
								</Route>
							</Switch>
						</Router>
					</ThemeProvider>
				)
			}
		</>
	)
}
