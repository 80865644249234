import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Tabs, Tab, IconButton, Tooltip, Typography, Popover, MenuItem } from '@material-ui/core';
import { AccountCircle, WbSunny, NightsStay, PowerSettingsNew } from '@material-ui/icons';
import { useTheme, ThemeType } from 'src/contexts/theme/ThemeContext';
import { useHistory, useLocation, matchPath } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

interface TopbarMenuItem {
	label: string;
	icon: JSX.Element;
	route: string;
}

interface TopbarProps {
	children: JSX.Element;

	/**
	 * Define content on you own.
	 */
	content?: JSX.Element;

	logo?: JSX.Element;
	menuItems?: TopbarMenuItem[];
	username?: string;
	onLogout?: () => void;
}

const topbarHeight = '42px';

const useTopbarStyles = makeStyles({
	container: {
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'column'
	},
	topbarContentContainer: {
		width: '100%',
		height: topbarHeight,
		boxShadow: '0px 3px 5px rgba(0,0,0,0.2)',
		zIndex: 1000
	},
	childrenContainer: {
		flexGrow: 1,
		height: '100%',
		overflow: 'auto'
	},
	topbarContent: {
		display: 'flex',
		flexDirection: 'row',
		height: '100%'
	},
	tab: {
		minHeight: '42px',
		height: '42px'
	},
	label: {
		marginLeft: 10
	},
	logoSection: {
		width: '180px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-start'
	},
	rightSection: {
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'center',
		flexGrow: 1,
		marginRight: 15,
		'& > *': {
			marginLeft: 14
		}
	},
	popover: {
		pointerEvents: 'none',
	},
	popoverContent: {
		pointerEvents: 'auto',
	}
})

export const Topbar = (props: TopbarProps) => {
	// responsive stuff
	const isPortrait = useMediaQuery({ query: '(max-width: 1224px)' })
	const classes = useTopbarStyles();
	const history = useHistory();
	const location = useLocation();
	const { theme, setTheme } = useTheme();

	const menuItems = props.menuItems || [];

	const [tab, setTab] = React.useState(0);
	React.useEffect(() => {
		const selectedTab = menuItems.findIndex(item => matchPath(location.pathname, item.route));
		setTab(selectedTab);
	}, [location, menuItems])
	const handleTabChange = React.useCallback((_event, newTab) => setTab(newTab), []);

	// user popover, should extract this behavior
	const [anchorEl, setAnchorEl] = React.useState<(EventTarget & HTMLDivElement) | null>(null);
	const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => setAnchorEl(event.currentTarget);
	const handleClose = () => setAnchorEl(null);

	// const [menuDrawerOpen, setMenuDrawerOpen] = React.useState(false);

	return (
		<div className={classes.container}>
			<div className={classes.topbarContentContainer}>
				<div className={classes.topbarContent}>
					<div className={classes.logoSection}
						// onClick={isPortrait ? () => setMenuDrawerOpen(true) : undefined}
					>
						{props.logo}
					</div>
					{
						isPortrait ? (
							null
							// <Drawer overlay open={menuDrawerOpen} onClose={() => setMenuDrawerOpen(false)} anchor={DrawerAnchor.Bottom} zIndex={5000}>
							// 	{() => <>
							// 		<List component="nav" aria-label="secondary mailbox folders">
							// 			<ListItem button>
							// 				<ListItemText primary="Trash" />
							// 			</ListItem>
							// 			<ListItem button>
							// 				<ListItemText primary="Spam" />
							// 			</ListItem>
							// 		</List>
							// 	</>}
							// </Drawer>
						) : (
								<Tabs
									classes={{ root: classes.tab }}
									value={tab}
									indicatorColor="primary"
									textColor="primary"
									onChange={handleTabChange}
								>
									{
										menuItems.map(item => (
											<Tab
												key={item.route}
												onClick={_ => history.push(item.route)}
												label={<div>
													{item.icon}
													<span className={classes.label}>{item.label}</span>
												</div>}
												classes={{ root: classes.tab }}
											/>
										))
									}
								</Tabs>
							)
					}
					<div className={classes.rightSection}>
						{
							!isPortrait &&
							<Typography variant="subtitle1">
								{props.username}
							</Typography>
						}
						<div
							onClick={handleClick}
						>
							<IconButton>
								<AccountCircle style={{ fontSize: 24 }} />
							</IconButton>
						</div>
						{
							theme.type === ThemeType.Light ? (
								<Tooltip title='Tamna tema'>
									<IconButton onClick={_ => setTheme({ ...theme, type: ThemeType.Dark })}>
										<NightsStay style={{ fontSize: 24 }} />
									</IconButton>
								</Tooltip>
							) : (
									<Tooltip title='Svijetla tema'>
										<IconButton onClick={_ => setTheme({ ...theme, type: ThemeType.Light })}>
											<WbSunny style={{ fontSize: 24 }} />
										</IconButton>
									</Tooltip>
								)
						}
					</div>
				</div>
				<Popover
					anchorEl={anchorEl}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'right',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'right',
					}}
					onClose={handleClose}
					open={!!anchorEl}
				>
					<MenuItem onClick={_ => history.push('/login')}>
						<div onClick={_ => props.onLogout ? props.onLogout() : null}>
							<PowerSettingsNew style={{ verticalAlign: 'middle' }} fontSize={'small'} />
							<span className={classes.label}>Odjava</span>
						</div>
					</MenuItem>
				</Popover>
			</div>
			<div className={classes.childrenContainer}>
				{props.children}
			</div>
		</div>
	)
}
