import Axios from 'axios';

interface PagingOptions {
	number: number;
	size: number;
}

enum SortOrder {
	ASC = 'ASC',
	DESC = 'DESC'
}

interface Options<T> {
	propsToDate: (keyof T)[];
	consoleLogRoute?: boolean;
}

export class GenericApiCrudService<T> {
	constructor(protected readonly baseRoute: string, protected readonly opts?: Options<T>) {}

	private transformDateProps(entity: T) {
		const dateProps: any = {};
		(this.opts?.propsToDate || []).forEach(prop => {
			dateProps[prop] = new Date(entity[prop] as any)
		});

		return {
			...entity,
			...dateProps
		}
	}

	getOne(id: string): Promise<T> {
		const url = `${this.baseRoute}/${id}`;
		if (this.opts?.consoleLogRoute) console.log(`api crud find: ${url}`);
		return Axios.get(url).then(res => this.transformDateProps(res.data as T));
	}

	getMany(ids: string[]): Promise<T[]> {
		const url = `${this.baseRoute}/${ids.join(',')}`;
		if (this.opts?.consoleLogRoute) console.log(`api crud find: ${url}`);
		return Axios.get(url).then(res => (res.data as T[]).map(x => this.transformDateProps(x)));
	}

	find(pagingOpts?: PagingOptions, filters?: { [key: string]: string }, sortOpts?: [string, 'ASC' | 'DESC']) {
		let url = this.baseRoute + constructQueryParams(pagingOpts, filters, sortOpts);
		
		if (this.opts?.consoleLogRoute) console.log(`api crud find: ${url}`);
		
		return Axios.get(url).then(res => res.data).then(({ data, total }) => {
			return {
				data: data.map((entity: T) => this.transformDateProps(entity)),
				total: total
			}
		}) as Promise<{ data: T[], total: number }>
	}
}

const constructQueryParams = (pagingOpts?: PagingOptions, filters?: { [key: string]: string }, sortOpts?: [string, 'ASC' | 'DESC']) => {
	let queryParamsStr = '?';

	if (pagingOpts) {
		queryParamsStr += `&pageSize=${pagingOpts.size}`;
		queryParamsStr += `&pageNumber=${pagingOpts.number}`;
	}

	if (sortOpts) {
		queryParamsStr += `&${sortOpts[1] === SortOrder.ASC ? `sort=asc:${sortOpts[0]}` : `sort=desc:${sortOpts[0]}`}`;
	}

	for (let [key, value] of Object.entries(filters || {})) {
		queryParamsStr += `&${key}=${value}`;
	}

	return queryParamsStr;
}
