import React from 'react';
import { makeStyles} from '@material-ui/core';
import Table, { RowSelectionColumn, DateColumn,  FetchPageAsPromise } from '../reusable/Table';
import { userService } from 'src/api/api';

const useUsersViewStyles = makeStyles({
	container: {
		height: '100%',
		width: '100%',
		position: 'relative',
		overflow: 'hidden'
	},
	tableContainer: {
		height: '100%',
		padding: '20px',
		paddingBottom: '20px',
		overflow: 'auto'
	}
})

export const UsersView = () => {
	const classes = useUsersViewStyles();
	const [, setDrawerDomEl] = React.useState<HTMLDivElement | null>(null);

	const getPage: FetchPageAsPromise<any> = React.useCallback((pagingOpts, sortOpts) => {
		return userService.find(pagingOpts, undefined, sortOpts ? [sortOpts.sortBy, sortOpts.sortDir] : undefined)
	}, [])

	return (
		<div ref={el => setDrawerDomEl(el)} className={classes.container}>
			<div className={classes.tableContainer}>
				<Table fetchTablePageFn={getPage} options={{ title: 'Korisnici', defaultPageSize: 25 }}>
					<RowSelectionColumn />
					<DateColumn field='email' label='Korisnik' locale='hr' />
					<DateColumn field='createdAt' label='Kreirano' locale='hr' type='date' />
					<DateColumn field='updatedAt' label='Izmijenjeno' locale='hr' type='date' />
				</Table>
			</div>
		</div>
	)
}
