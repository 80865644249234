import React from 'react';
import Logo from 'src/assets/tm-logo-color.png';
import { Topbar } from 'src/components/layout/Topbar';

import { RestoreFromTrash, SupervisorAccount, Map } from '@material-ui/icons';
import { Switch, Route, Redirect, useHistory } from 'react-router-dom';
import { MapView } from 'src/components/views/MapView';
import { BinsView } from 'src/components/views/BinsView';
import { UsersView } from 'src/components/views/UsersView';
import { authService } from 'src/api/api';

export const App = () => {
	const history = useHistory();
  if (!authService.isLoggedIn()) {
    history.push('/login');
  }
  const username = authService.getUserDetails()?.email;
  const onLogout = () => {
    authService.logout();
    history.push('/login');
  }

  return (
    <Topbar
      logo={<img src={Logo} height={28} alt='logo' />}
      menuItems={[
        { label: 'Karta', route: '/map', icon: <Map style={{ verticalAlign: 'middle' }} fontSize={'small'} /> },
        { label: 'Spremnici', route: '/bins', icon: <RestoreFromTrash style={{ verticalAlign: 'middle' }} fontSize={'small'} /> },
        { label: 'Korisnici', route: '/users', icon: <SupervisorAccount style={{ verticalAlign: 'middle' }} fontSize={'small'} /> },
      ]}
      username={username}
      onLogout={onLogout}
    >
      <Switch>
        <Route exact path='/'>
          <Redirect to='/map' />
        </Route>
        <Route path='/map'>
          <MapView />
        </Route>
        <Route path='/bins'>
          <BinsView />
        </Route>
        <Route path='/users'>
          <UsersView />
        </Route>
      </Switch>
    </Topbar>
  );
}
