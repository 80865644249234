import React from 'react';
import { makeStyles, IconButton } from '@material-ui/core';
import { Visibility } from '@material-ui/icons';
import Table, { Column, FetchPageAsPromise, RowSelectionColumn, DateColumn } from '../reusable/Table';
import { binService } from 'src/api/api';
import { translateBinType } from '../helpers';
import { BinDetailsDrawer } from '../BinDetails';

const useBinViewStyles = makeStyles({
	container: {
		height: '100%',
		width: '100%',
		position: 'relative',
		overflow: 'hidden'
	},
	tableContainer: {
		height: '100%',
		padding: '20px',
		paddingBottom: '20px',
		overflow: 'auto'
	}
})

export const BinsView = () => {
	const classes = useBinViewStyles();
	const [drawerDomEl, setDrawerDomEl] = React.useState<HTMLDivElement | null>(null);
	const [selectedBinId, setSelectedBinId] = React.useState<string | undefined>(undefined);

	const getPage: FetchPageAsPromise<any> = React.useCallback((pagingOpts, sortOpts) => {
		return binService.find(pagingOpts, undefined, sortOpts ? [sortOpts.sortBy, sortOpts.sortDir] : undefined)
	}, [])

	return (
		<div ref={el => setDrawerDomEl(el)} className={classes.container}>
			<BinDetailsDrawer overlay id={selectedBinId} container={drawerDomEl} width='50%' onClose={() => setSelectedBinId(undefined)} />
			<div className={classes.tableContainer}>
				{/* <Filters filters={}/> */}
				<Table fetchTablePageFn={getPage} options={{ title: 'Spremnici', defaultPageSize: 25 }}>
					<RowSelectionColumn />
					<DateColumn field='name' label='Ime' locale='hr' />
					<Column label='Popunjenost' sortBy='fillPercentage'>
						{(row: any) => <>{row.fillPercentage !== null ? `${row.fillPercentage}%` : '/'}</>}
					</Column>
					<Column label='Baterija' sortBy='batteryPercentage'>
						{(row: any) => <>{row.batteryPercentage !== null ? `${row.batteryPercentage}%` : '/'}</>}
					</Column>
					<Column label='Tip spremnika' sortBy='type'>
						{(row: any) => <>{translateBinType(row.type)}</>}
					</Column>
					<DateColumn field='createdAt' label='Napravljeno' locale='hr' />
					<DateColumn field='updatedAt' label='Izmijenjeno' locale='hr' />
					<Column width={'5%'}>
						{(row: any) => <IconButton onClick={_ => setSelectedBinId(row.id)}><Visibility /></IconButton>}
					</Column>
				</Table>
			</div>
		</div>
	)
}
