import React from 'react';
import { CircularProgress, Typography, Fab } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Drawer, DrawerAnchor } from 'src/components/reusable/Drawer';
import { Clear } from '@material-ui/icons';
import { TimeSeriesChart, TimeSeriesPoint } from 'src/components/reusable/charts/TimeSeriesChart';
import { useCurrentTheme, ThemeType } from 'src/contexts/theme/ThemeContext';
import { binService } from 'src/api/api';
import { translateBinType } from 'src/components/helpers';
import { useMediaQuery } from 'react-responsive';

const useBinDetailsStyles = makeStyles({
	container: {
		position: 'relative',
		paddingTop: '20px',
		maxHeight: '100%',
		overflowY: 'scroll',
	},
	loading: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		width: '100%',
		height: '100%'
	},
	info: {
		paddingLeft: '20px',
		display: 'flex',
		flexWrap: 'wrap',
		'& > *': {
			marginRight: 40
		}
	},
	chartContainer: {
		height: '400px',
		marginTop: '50px'
	}
})

interface BinDetailsProps {
	id: string;
}

const LabeledText = ({ label, value }: { label: string, value: string }) => {
	return (
		<div>
			<Typography variant='caption' color="textSecondary">
				{label}
			</Typography>
			<Typography variant="subtitle1">
				{value}
			</Typography>
		</div>
	)
}

export const BinDetails = (props: BinDetailsProps) => {
	const { type, colors } = useCurrentTheme();
	const classes = useBinDetailsStyles();
	const [bin, setBin] = React.useState<any | undefined>(undefined);
	const [fillPercentageTimeSeries, setFillPercentageTimeSeries] = React.useState<TimeSeriesPoint[] | undefined>()
	const [BatteryPercentageTimeSeries, setBatteryPercentageTimeSeries] = React.useState<TimeSeriesPoint[] | undefined>()
	const isPortrait = useMediaQuery({ query: '(max-width: 1224px)' });

	React.useEffect(() => {
		setBin(undefined);
		setFillPercentageTimeSeries(undefined);
		setBatteryPercentageTimeSeries(undefined);
		binService.getOne(props.id).then(bin => setBin(bin));
		binService.getFillPercentageTimeseries(props.id).then(data => setFillPercentageTimeSeries(data))
		binService.getBatteryPercentageTimeseries(props.id).then(data => setBatteryPercentageTimeSeries(data))
	}, [props.id]);

	return (
		<>
			{
				bin ?
					<div className={classes.container}>
						<div className={classes.info}>
							<LabeledText label='Ime' value={bin.name} />
							<LabeledText label='Tip spremnika' value={translateBinType(bin.type)} />
							<LabeledText label='Popunjenost' value={bin.fillPercentage !== null ? `${bin.fillPercentage}%` : '/'} />
							<LabeledText label='Baterija' value={bin.batteryPercentage !== null ? `${bin.batteryPercentage}%` : '/'} />
							<LabeledText label='Zadnja promjena' value={(bin.updatedAt as Date).toLocaleString('hr')} />
						</div>
						<div className={classes.chartContainer} style={{
							margin: isPortrait ? undefined : '15px'
						}}>
							{
								fillPercentageTimeSeries ? (
									<TimeSeriesChart
										legendLabel='Popunjenost %'
										timeseries={fillPercentageTimeSeries}
										lineColor={colors.main}
										textColor={type === ThemeType.Dark ? 'white' : 'black'}
										gridLinesColor={type === ThemeType.Dark ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)'}
										yAxesSettings={{ min: 0, max: 100 }} />
								) : null
							}
						</div>
						<div className={classes.chartContainer} style={{
							margin: isPortrait ? undefined : '15px'
						}}>
							{
								BatteryPercentageTimeSeries ? (
									<TimeSeriesChart
										legendLabel='Baterija %'
										timeseries={BatteryPercentageTimeSeries}
										lineColor={colors.main}
										textColor={type === ThemeType.Dark ? 'white' : 'black'}
										gridLinesColor={type === ThemeType.Dark ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)'}
										yAxesSettings={{ min: 0, max: 100 }} />
								) : null
							}
						</div>
					</div>
					:
					<div className={classes.loading}>
						<CircularProgress size={80} thickness={2} />
					</div>
			}
		</>
	)
}

interface BinDetailsDrawerProps {
	id?: string;
	width?: string;
	height?: string;
	container?: HTMLElement | null;
	onClose?: () => void | any;
	overlay?: boolean;
}

export const BinDetailsDrawer = (props: BinDetailsDrawerProps) => {
	// responsive stuff
	const isPortrait = useMediaQuery({ query: '(max-width: 1224px)' })

	const [binId, setBinId] = React.useState(props.id)
	React.useEffect(() => {
		setBinId(props.id)
	}, [props.id]);
	const onClose = props.onClose;
	const closeHandler = React.useCallback(() => {
		if (onClose) onClose();
		setBinId(undefined);
	}, [onClose])

	return (
		<Drawer
			overlay={isPortrait ? true : props.overlay}
			onClose={() => closeHandler()}
			open={!!binId}
			anchor={isPortrait ? DrawerAnchor.Bottom : DrawerAnchor.Right}
			container={props.container}
			width={isPortrait ? undefined : (props.width || '50%')}
			height={isPortrait ? (props.height || '80%') : undefined}
		>
			{(open, setOpen) => (
				<div style={{
					position: 'relative',
					height: '100%',
					width: '100%'
				}}>
					{
						open ? <Fab color="primary" aria-label="add" style={{
							position: 'absolute',
							// landscape pos
							left: isPortrait ? undefined : -50,
							// portrait pos
							right: isPortrait ? 10 : undefined,
							top: isPortrait ? -50 : 10
						}}
							onClick={_ => closeHandler()}
						>
							<Clear />
						</Fab> : <></>
					}
					{!binId ? <></> : <BinDetails id={binId} />}
				</div>
			)}
		</Drawer>
	)
}
