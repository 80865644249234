import { GenericApiCrudService } from 'src/api/reusable/generic-api-crud-service';
import { GenericAuthService } from 'src/api/reusable/generic-auth-service';
import Axios from 'axios';

export const userService = new GenericApiCrudService<any>('/api/users', {
	propsToDate: ['createdAt', 'updatedAt'],
	consoleLogRoute: true
});

class BinService extends GenericApiCrudService<any> {
	constructor() {
		super('/api/bins', {
			propsToDate: ['createdAt', 'updatedAt'],
			consoleLogRoute: true
		})
	}

	getFillPercentageTimeseries(id: string, from?: Date, to?: Date) {
		const url = `${this.baseRoute}/${id}/fill-percentage`;
		if (this.opts?.consoleLogRoute) console.log(url);
		return Axios.get(url).then(res => res.data)
	}

	getBatteryPercentageTimeseries(id: string, from?: Date, to?: Date) {
		const url = `${this.baseRoute}/${id}/battery-percentage`;
		if (this.opts?.consoleLogRoute) console.log(url);
		return Axios.get(url).then(res => res.data)
	}
}

export const binService = new BinService();

export const authService = new GenericAuthService<{email: string}>('/api/login');
