import Axios from 'axios';
import jwtDecode from 'jwt-decode';

interface Options {
	localStorageKey?: string;
}

const DEFAULT_LOCAL_STORAGE_KEY = 'ACCESS_TOKEN';

export class GenericAuthService<T> {

	private localStorageKey: string;
	private accessToken: string | null = null;
	private userDetails: T | null = null;

	constructor(protected readonly loginRoute: string, options?: Options) {
		this.localStorageKey = options?.localStorageKey || DEFAULT_LOCAL_STORAGE_KEY;
		this.setAccessToken(localStorage.getItem(this.localStorageKey));
	}

	login(username: string, password: string): Promise<void> {
		return Axios.post(
			this.loginRoute,
			{
				username,
				password,
			},
		)
			.then(res => this.setAccessToken(res?.data?.access_token))
			.catch(() => {
				throw new Error('Login failed');
			});
	}

	getAccessToken(): string | null {
		return this.accessToken;
	}

	getUserDetails(): T | null {
		return this.userDetails;
	}

	isLoggedIn(): boolean {
		console.log('ac', this.accessToken)
		return !!this.accessToken;
	}

	logout() {
		localStorage.removeItem(this.localStorageKey);
		this.accessToken = null;
	}

	private setAccessToken(accessToken: string | null) {
		this.accessToken = accessToken;
		if (this.accessToken) {
			this.userDetails = jwtDecode(this.accessToken);
			localStorage.setItem(this.localStorageKey, this.accessToken);
		} else {
			this.userDetails = null;
		}
	}

}
