import React from 'react';
import { Pin } from 'src/components/reusable/maps/types';
import { Pins } from 'src/components/reusable/maps/Pins';
import { Card, makeStyles, FormControl, InputLabel, Select, MenuItem, CircularProgress } from '@material-ui/core';
import { ColorLabelLegend } from '../reusable/maps/leaflet-helper';
import { BinDetailsDrawer } from 'src/components/BinDetails';
import { binService } from 'src/api/api';
import { binTypeTranslations, translateBinType } from 'src/components/helpers';
import { useMediaQuery } from 'react-responsive';

const labelRenderer = (pin: Pin): JSX.Element => {
  const { name, fillPercentage, batteryPercentage, type } = pin.details;
  const rows = [
    ['Ime', name],
    ['Popunjenost', fillPercentage !== null ? `${fillPercentage}%` : '/'],
    ['Baterija', batteryPercentage !== null ? `${batteryPercentage}%` : '/'],
    ['Tip', translateBinType(type)],
  ];
  return (
    <div>
      {
        rows.map(([label, value], idx) => {
          return (
            <div key={idx}>
              <div key='label' style={{ display: 'inline-block', fontWeight: 500 }}>
                {label}:
							</div>
              <div key='value' style={{ display: 'inline-block', marginLeft: '5px' }}>
                {value}
              </div>
            </div>
          );
        })
      }
    </div>
  );
};

const useMapViewStyles = makeStyles({
  mapOverlay: {
    pointerEvents: 'none',
    position: 'absolute',
    bottom: 15,
    left: 15,
    zIndex: 400, // leaflet ???
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    '& > *': {
      marginRight: 10,
      pointerEvents: 'all'
    }
  },
  card: {
    padding: 10
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%'
  },
})

const fillPercentageLegend: [string, string][] = [
  ['green', '0-40%'],
  ['orange', '40-70%'],
  ['red', '70-100%']
]

const getFillColor = (fillPercentage: number) => {
  if (fillPercentage < 40) return 'green';
  if (fillPercentage < 70) return 'orange';
  return 'red'
}

const getPinsBinsByType = (type?: string): Promise<any[]> => {
  return binService.find(undefined, type ? { type: type } : undefined).then(({ data, total }) => {
    const binsWithLocation = data.filter(bin => bin.latitude && bin.longitude);
    return binsWithLocation.map(bin => {
      return {
        id: bin.id,
        color: getFillColor(bin.fillPercentage),
        location: {
          lat: bin.latitude,
          lng: bin.longitude,
        },
        details: bin,
      }
    });
  })
}

export const MapView = () => {
  const classes = useMapViewStyles();
  const [drawerDomEl, setDrawerDomEl] = React.useState<HTMLDivElement | null>(null);
  const [pinsBins, setPinsBins] = React.useState<any[] | undefined>(undefined);
  const [selectedBinId, setSelectedBinId] = React.useState<string | undefined>(undefined);
  const [binType, setBinType] = React.useState('');
	// responsive stuff
	const isPortrait = useMediaQuery({ query: '(max-width: 1224px)' })

  React.useEffect(() => {
    getPinsBinsByType(binType).then(pinsBins => setPinsBins(pinsBins));
  }, [binType])

  return (
    <div
      ref={el => setDrawerDomEl(el)}
      style={{
        height: '100%',
        width: '100%',
        position: 'relative',
        overflow: 'hidden'
      }}
    >
      <BinDetailsDrawer id={selectedBinId} container={drawerDomEl} width='50%' onClose={() => setSelectedBinId(undefined)} />
      <div key='pins-container' style={{
        height: '100%',
        width: '100%',
      }}
      // onMouseDown={_ => setSelectedBinId(undefined)}
      >
        {
          pinsBins ? (
            <Pins
              pins={pinsBins}
              height='100%'
              width='100%'
              onPinClick={(pinBin: any) => setSelectedBinId(pinBin.id)}
              panOffset={isPortrait ? undefined :{
                percentage: true,
                x: -0.25,
                y: 0
              }}
              labelRenderer={labelRenderer}
            />
          ) : (
              <div className={classes.loading}>
                <CircularProgress size={80} thickness={2} />
              </div>
            )
        }
      </div>
      <div key='overlay-controls-container' className={classes.mapOverlay}>
        <Card className={classes.card}>

          <div style={{ fontWeight: 300, marginBottom: '15px' }}>Popunjenost:</div>
          <ColorLabelLegend colorsLabels={fillPercentageLegend} />
        </Card>
        <Card style={{ paddingLeft: 10, paddingRight: 10, paddingBottom: 5 }}>
          <FormControl variant="outlined" style={{ minWidth: 200 }}>
            <InputLabel>Tip spremnika</InputLabel>
            <Select
              onChange={event => setBinType(event.target.value as string)}
              label="Tip spremnika"
              value={binType}
            >
              <MenuItem key='empty' value=''>/</MenuItem>
              {Object.entries(binTypeTranslations).map(([type, typeTranslation], idx) => {
                return <MenuItem key={idx} value={type}>{typeTranslation}</MenuItem>
              })}
            </Select>
          </FormControl>
        </Card>
      </div>
    </div>
  )
}
