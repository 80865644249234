import React, { CSSProperties } from 'react';
import ReactDOM from 'react-dom';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { Theme } from '@material-ui/core';

export enum DrawerAnchor {
  Left = 'left',
  Right = 'right',
  Bottom = 'bottom',
  Top = 'top'
}

interface DrawerProps {
  /**
   * From which side should Drawer open. Defaults to right anchor.
   */
  anchor?: DrawerAnchor;
  open?: boolean;
  onClose?: () => void;
  // onChange?: (open: boolean) => void;
  /**
   * Function to display Drawer content - argument helpers open/setOpen can be used to close/open Drawer.
   */
  children?: (open: boolean, setOpen: (open: boolean) => void) => JSX.Element;
  width?: number | string;
  height?: number | string;
  container?: HTMLElement | null;
  overlay?: boolean;
  zIndex?: number;
}

const useDrawerStyles = makeStyles((theme: Theme) => ({
  overlay: {
    zIndex: 400,
    position: 'absolute',
    visibility: 'hidden',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0,0,0,0.32)',
    transition: 'opacity 500ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
    opacity: 0
  },
  overlayOpened: {
    visibility: 'visible',
    opacity: 1
  },
  drawer: {
    zIndex: 401,
    transition: 'transform .3s ease, box-shadow 2s ease',
    boxShadow: 'none',
    position: 'absolute',
    backgroundColor: theme.palette.background.default,
  },
  drawerOpened: {
    transition: 'transform .3s ease, box-shadow .3s ease',
    boxShadow: '0px 0px 45px 0px rgba(0,0,0,0.75)',
  },
  // anchoring
  horizontalDrawer: {
    height: '100%',
    top: 0,
    bottom: 0
  },
  verticalDrawer: {
    width: '100%',
    right: 0,
    left: 0
  },
  drawerRight: {
    right: 0,
    transform: 'translateX(100%)',
    '&$drawerOpened': {
      transform: 'translateX(0%)',
    }
  },
  drawerLeft: {
    left: 0,
    transform: 'translateX(-100%)',
    '&$drawerOpened': {
      transform: 'translateX(0%)',
    }
  },
  drawerTop: {
    top: 0,
    transform: 'translateY(-100%)',
    '&$drawerOpened': {
      transform: 'translateX(0%)',
    }
  },
  drawerBottom: {
    bottom: 0,
    transform: 'translateY(100%)',
    '&$drawerOpened': {
      transform: 'translateX(0%)',
    }
  }
}))

const isVertical = (anchor: DrawerAnchor) => anchor === DrawerAnchor.Bottom || anchor === DrawerAnchor.Top;
const isHorizontal = (anchor: DrawerAnchor) => anchor === DrawerAnchor.Left || anchor === DrawerAnchor.Right;

/**
 * TODO: make it possible to not define width/height in advance (check CSS above) so that animation works with auto width/height
 * @param props 
 */
export const Drawer = (props: DrawerProps) => {
  const classes = useDrawerStyles();
  const anchor = props.anchor ?? DrawerAnchor.Right;

  const [open, setOpen] = React.useState(props.open || false);
  React.useEffect(() => {
    setOpen(props.open || false)
  }, [props.open]);

  const openWidthHeight: CSSProperties = {
    width: props.width,
    height: props.height
  };

  return (
    <>
      {ReactDOM.createPortal(
        <>
          {props.overlay ? <div
            onClick={_ => {
              setOpen(false)
              if (props.onClose) props.onClose();
            }}
            className={clsx(classes.overlay, {
              [classes.overlayOpened]: open
            })}
          /> : <></>}

          <div className={clsx(classes.drawer, {
            [classes.drawerOpened]: open,
            [classes.horizontalDrawer]: isHorizontal(anchor),
            [classes.verticalDrawer]: isVertical(anchor),
            [classes.drawerRight]: anchor === DrawerAnchor.Right,
            [classes.drawerLeft]: anchor === DrawerAnchor.Left,
            [classes.drawerTop]: anchor === DrawerAnchor.Top,
            [classes.drawerBottom]: anchor === DrawerAnchor.Bottom
          })}
            style={openWidthHeight}
          >
            {props.children ? props.children(open, setOpen) : <></>}
          </div>
        </>,
        props.container ? props.container : document.body
      )}
    </>
  )
}
