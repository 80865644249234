export const binTypeTranslations: { [key: string]: string } = {
	'mixedWaste': 'Miješani otpad',
	'glass': 'Staklo',
	'paper': 'Papir',
	'textile': 'Tekstil',
	'plastic': 'Plastika',
}

export const translateBinType = (type: string) => {
	const translation = binTypeTranslations[type];
	return translation || 'Nepoznato';
}
