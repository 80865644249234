
import React from 'react';
import 'leaflet/dist/leaflet.css';
import * as L from 'leaflet';
import { initializeLeafletMap, createLegend, ColorLabelLegend, getPinIcon, changeLeafletMapStyle, createTooltip } from 'src/components/reusable/maps/leaflet-helper';
import { Pin } from 'src/components/reusable/maps/types';
import { withTheme, Theme } from '@material-ui/core';

interface PinsProps {
	pins: Pin | Pin[];
	labelRenderer?: (pin: Pin) => JSX.Element;
	theme?: Theme;
	/**
	 * Event listener for pin click.
	 * Parameter pin is the same object passed in to React component.
	 */
	onPinClick?: (pin: Pin) => void;
	width?: number | string;
	height?: number | string;
	legendColorLabels?: [string, string][];
	autoFocus?: boolean;
	zoomLvl?: number;
	panOffset?: {
		percentage?: boolean;
		x: number,
		y: number
	}
}

/**
 * TODO: secure access tokens!
 * TODO: refactor as functional component
 */
class PinsRaw extends React.Component<PinsProps, any> {
	private mapId = 'map-' + Math.random();
	private map!: L.Map;
	private layer?: L.FeatureGroup;
	private legend?: L.Control;
	private defaultPinColor = '#00AEEF';
	node: any;

	// constructor(props: PinsProps) {
	// 	super(props);
	// 	setTimeout(() => {
	// 		props.onPinClick!({} as any)
	// 	}, 1000)
	// }

	updateMap(pins: Pin | Pin[], legendColorLabels?: [string, string][]) {

		const pinsArray: Pin[] = Array.isArray(pins) ? pins : [pins];

		// reset layers
		if (this.layer) {
			this.map.removeLayer(this.layer);
		}

		this.layer = L.featureGroup().addTo(this.map);

		if (!pins) return;

		// add legend
		if (legendColorLabels) {
			if (this.legend) this.map.removeControl(this.legend);
			this.legend = createLegend('topright', <ColorLabelLegend colorsLabels={legendColorLabels} />);
			this.legend.addTo(this.map);
		}

		// map scroll disabled by default, enable on click inside map, disable on click outside of map
		this.map.scrollWheelZoom.disable();

		// set pins
		const { onPinClick } = this.props;
		pinsArray.forEach(pin => {
			const marker = L.marker(pin.location, { icon: getPinIcon(pin.color ? pin.color : this.defaultPinColor) }).addTo(this.layer!);
			const labelRenderer = this.props.labelRenderer;
			if (labelRenderer) {
				marker.bindTooltip(
					createTooltip(
						labelRenderer(pin)
					),
				);
			} else if (pin.label) {
				marker.bindTooltip(pin.label);
			}
			if (pin.link) {
				// TODO: should we use router?
				marker.on('click', _ => window.location = pin.link as any)
			} else if (onPinClick) {
				marker.on('click', _ => {
					this.panToWithOffset(pin.location.lat, pin.location.lng);
					// this.map.panTo([pin.location.lat, pin.location.lng]);
					setTimeout(_ => onPinClick(pin), 0);
				})
			}
		})

		// small hack
		setTimeout(() => {
			if (this.layer?.getBounds().isValid() && this.props.pins instanceof Array) {
				this.map.fitBounds(this.layer.getBounds(), { padding: [50, 50] });
			}
		}, 300);
	}

	panToWithOffset(lat: number, lng: number) {
		if (!this.props.panOffset) {
			this.map.panTo([lat, lng]);
			return;
		}

		let { percentage, x: offsetX, y: offsetY } = this.props.panOffset;
		// let offsetX = x;
		// let offsetY = y;
		if (percentage) {
			const { width, height } = this.map.getContainer().getBoundingClientRect();
			offsetX *= width;
			offsetY *= height;
		}
		var containerX = this.map.latLngToContainerPoint([lat, lng]).x - offsetX;
		var containerY = this.map.latLngToContainerPoint([lat, lng]).y - offsetY;
		var point = this.map.containerPointToLatLng([containerX, containerY])
		return this.map.panTo(point)
	}


	componentDidMount() {
		this.map = initializeLeafletMap(this.mapId, this.props.theme?.palette.type);
		this.updateMap(this.props.pins, this.props.legendColorLabels);
		if (!(this.props.pins! instanceof Array) && this.props.zoomLvl) {
			this.map.setZoom(this.props.zoomLvl);
			this.map.setView(this.props.pins.location, this.map.getZoom());
		}
		if (this.props.autoFocus) {
			this.map.scrollWheelZoom.enable();
		}
		document.addEventListener('mousedown', this.handleClick, false);
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClick, false);
	}

	handleClick = (e: any) => {
		if (this.node.contains(e.target)) {
			this.map.scrollWheelZoom.enable();
		} else {
			this.map.scrollWheelZoom.disable();
		}
	}

	componentDidUpdate(prevProps: PinsProps) {
		if (prevProps.pins !== this.props.pins || prevProps.legendColorLabels !== this.props.legendColorLabels) {
			this.updateMap(this.props.pins, this.props.legendColorLabels);
		}
	}

	// TODO: really dependent on MaterialUI theme...
	componentWillReceiveProps(nextProps: PinsProps) {
		if (this.props.theme?.palette.type !== nextProps.theme?.palette.type && this.map) {
			changeLeafletMapStyle(this.map, nextProps.theme.palette.type);
			this.updateMap(this.props.pins, this.props.legendColorLabels);
		}
	}

	render() {
		let [width, height] = [
			this.props.width ? this.props.width : '100%',
			this.props.height ? this.props.height : 500
		]

		return (
			<div style={{ position: 'relative', width, height }}>
				<div id={this.mapId} ref={node => this.node = node} style={{
					position: 'absolute',
					top: 0,
					left: 0,
					bottom: 0,
					right: 0,
					height: '100%',
					width: '100%',
				}}></div>
			</div>
		)
	}
}

export const Pins = withTheme(PinsRaw);