import React, { useState } from 'react';
import { Card, TextField, Button, Snackbar, Tooltip, ThemeOptions } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useHistory } from 'react-router-dom';
import Logo from 'src/assets/tm-logo-color.png';
import SummerNatureRoad from 'src/assets/summer_nature_road_leaves_trees_90616_1920x1080_compressed_tinypng_com.jpg';
import { authService } from 'src/api/api';
import { Alert } from '@material-ui/lab';
import { useTheme, ThemeType } from 'src/contexts/theme/ThemeContext';
import { useMediaQuery } from 'react-responsive';

const useLoginStyles = makeStyles((theme: ThemeOptions) => ({
	container: {
		background: `url(${SummerNatureRoad}) no-repeat center center fixed`,
		backgroundSize: 'cover',
		height: '100%',
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	cardContainer: {
		width: '450px'
	},
	cardContent: {
		width: '80%',
		margin: '40px auto',
	},
	loginForm: {
		display: 'flex',
		flexDirection: 'column'
	},
	formInput: {
		marginTop: '10px'
	},
	button: {
		marginTop: '10px',
		backgroundColor: 'rgb(192, 111, 54)'
	},
	appTitle: {
		fontWeight: 200,
		fontSize: 24,
		textAlign: 'center'
	}
}))

export const Login = () => {
	const classes = useLoginStyles()
	const { theme: { type } } = useTheme();
	const history = useHistory();
	const [email, setEmail] = useState<string>('');
	const [password, setPassword] = useState<string>('');
	const [snackOpen, setSnackOpen] = useState<boolean>(false);
	const isPortrait = useMediaQuery({ query: '(max-width: 500px)' });

	const handleSubmit = React.useCallback((e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		authService.login(email, password)
			.then(() => {
				history.push('/');
			})
			.catch(() => {
				setSnackOpen(true);
			});
	}, [history, email, password])

	return (
		<div className={classes.container}>
			<Card elevation={3} className={classes.cardContainer} style={{
				opacity: type === ThemeType.Light ? 0.85 : 0.95,
				width: isPortrait ? '90%' : undefined
			}}>
				<div className={classes.cardContent}>
					<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '20px' }}>
						<Tooltip title='Telemetris d.o.o.' placement='top'>
							<img src={Logo} height={100} alt='logo' style={{ marginBottom: '20px' }} />
						</Tooltip>
						<div className={classes.appTitle}>
							UPRAVLJANJE OTPADOM
						</div>
					</div>
					<form className={classes.loginForm}
						onSubmit={handleSubmit}
					>
						<TextField
							className={classes.formInput}
							margin='normal'
							label="Email"
							type="email"
							onChange={e => setEmail(e.target.value)}
						/>
						<TextField
							className={classes.formInput}
							margin='normal'
							label="Password"
							type="password"
							onChange={e => setPassword(e.target.value)}
						/>
						<Button type='submit' variant='contained' color='primary' size='medium'
							className={classes.button}>
							Login
						</Button>
					</form>
					<div style={{ display: 'flex', justifyContent: 'center', fontSize: '14px', fontWeight: 200, paddingTop: '30px' }}>
						Telemetris d.o.o.
					</div>
				</div>
			</Card>

			<Snackbar open={snackOpen} autoHideDuration={6000} onClose={() => setSnackOpen(false)}>
				<Alert onClose={() => setSnackOpen(false)} severity='error'>
					Neuspješna prijava!
				</Alert>
			</Snackbar>
		</div>
	)
}
